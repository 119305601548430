@mixin textGradient {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

@mixin homeB2bComponent {
  position: absolute;
  height: 66px;
  min-width: 74px;
  background-color: #ededed;
  border-radius: 6px 6px 6px 6px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif;
  color: #424242;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 1;
  font-weight: 900;
  font-style: italic;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5px;
  span {
    margin-top: 3px;
  }
  right: 25px;
  bottom: 25px;
  @include mediaScreenContent('sm'){
    right: 50px;
    bottom: 50px;
  }
  @include mediaScreenContent('md'){
    right: 100px;
    bottom: 100px;
  }
}

@mixin buttonTransparent {
  margin-top: 100px;
  overflow: visible;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #BDBDBD;
  font-family: "Oswald", serif;
  color: #BDBDBD;
  font-size: 14px;
  letter-spacing: 1.12px;
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
  padding: 5px 50px;
  &:hover {
    text-decoration: none;
  }
}

@mixin homeCardText {
  font-family: "Oswald", serif;
  color: #9e9e9e;
  letter-spacing: 0px;
  line-height: 1.2;
  font-weight: 300;
  text-align: center;
}

@mixin greenButon {
  text-decoration: none;
  height: 42px;
  width: auto;
  padding: 10px 50px;
  background: linear-gradient(180deg, #b4ec50 0%, rgba(105,177,49,1) 100%);
  border-radius: 15px 15px 15px 15px;
  border: none;
  font-family: "Lato", serif;
  color: #212121;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  &:hover {
    background: linear-gradient(180deg, darken(#b4ec50, 10) 0%, rgba(105,177,49,1) 100%);
    text-decoration: none;
    color: #212121;
  }
}

@mixin pagesB2bFixed {
  .b2b {
    @include homeB2bComponent;
    position: fixed;
    right: 25px;
    top: 25px;
    @include mediaScreenContent('sm'){
      right: 50px;
      top: 50px;
    }
    @include mediaScreenContent('md'){
      right: 100px;
      top: 100px;
    }
  }
}

@mixin pagesB2bContainer {
  .b2b-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .b2b-2 {
      @include homeB2bComponent;
      position: unset;
      height: auto;
      width: auto;
      font-size: 25px;
      width: auto;
      img {
        height: 56px;
      }
    }
  }
}

@mixin pagesUlWithIcons {
  ul {
    padding: 0;
    @include mediaScreenContent('md'){
      padding-left: 20px;
    }
    list-style: none;
    li {
      display: flex;
      margin-bottom: 10px;
      font-family: "Roboto", sans-serif;
      color: #bdbdbd;
      font-size: 17px;
      @include mediaScreenContent('sm'){
        font-size: 19px;
      }
      @include mediaScreenContent('md'){
        font-size: 22px;
      }
      letter-spacing: 0px;
      line-height: 1.2;
      font-weight: 400;
      text-align: left;
    }
    img, object {
      width: 26px;
      margin-right: 10px;
      @include mediaScreenContent('sm'){
        font-size: 30px;
        margin-right: 15px;
      }
    }
  }
}