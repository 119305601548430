.main-menu {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  text-transform: uppercase;
  a {
    color: inherit;
    text-decoration: none;
  }



  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .left-menu {
    a {
      padding: 10px 20px;
      img {
        height: 40px;
      }
    }
    @include mediaScreenContent('lg') {
      width: 100%;
      max-width: 220px;
    }
  }

  .mid-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      width: 100%;
      align-items: center;
      li {
        width: inherit;
      }
    }

    @include mediaScreenContent('md'){
      position: static;
      width: auto;
      background: none;
      ul {
        width: auto;
        li {
          width: 100px;
        }
      }
    }

    @include mediaScreenContent('lg'){
      ul {
        li {
          width: 120px;
        }
      }
    }


    li {
      text-align: center;
    }
    a {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: flex;
        text-align: center;
      }
      span:nth-child(2) {
        display: none;
      }
    }
    a:hover {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $gray300;
      text-transform: none;
      span:nth-child(1) {
        display: none;
      }
      span:nth-child(2) {
        display: flex;
      }
    }
  }

  .right-menu {
    a {
      padding: 10px 20px;
    }
  }
}
