.aboutus-page {
  background-color: white;
  height: auto;

  .main-bg {
    min-height: 477px;
    display: flex;
    align-items: center;
    & > div {
      font-family: "Oswald", serif;
      font-style: normal;
      text-align: left;
      margin: 15px;
      @include mediaScreenContent('md'){
        padding-left: 15%;
        margin: 0;
      }
      h1 {
        max-width: 456px;
        background: linear-gradient(228deg, rgba(238, 116, 51, 0.9) 0%, rgba(156, 64, 29, 0.9) 34%, rgba(117, 48, 21, 0.9) 52%, rgba(0, 0, 0, 0.9) 100%);
        opacity: 0.94;
        color: #ffffff;
        font-size: 38px;
        @include mediaScreenContent('md'){
          font-size: 42px;
        }
        font-weight: 500;
        margin: 0;
        padding: 5px 15px;
      }
      p {
        max-width: 539px;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.2);
        color: #212121;
        font-size: 25px;
        text-transform: uppercase;
        font-weight: 700;
        padding: 5px 15px;
      }
    }
  }

  .industry {
    position: relative;
    h3 {
      font-weight: bold;
      font-family: Oswald;
      text-align: center;
      font-size: 40px;
      margin-top: 150px;
      @include mediaScreenContent('sm'){
        font-size: 45px;
      }
      @include mediaScreenContent('md'){
        font-size: 55px;
      }
    }
    p {
      margin-top: 40px;
      margin-bottom: 50px;
      font-family: "Roboto", sans-serif;
      color: #424242;
      font-size: 22px;
      font-style: italic;
      text-align: center;
      font-weight: bold;
    }
    .iphone {
      display: block;
      position: static;
      width: 100%;
      overflow: hidden;
      img {
        width: 150%;
        margin-left: -25%;
      }
      @include mediaScreenContent('md'){
        width: auto;
        position: absolute;
        right: 50px;
        bottom: 100px;
        img {
          width: auto;
          margin: 0;
        }
      }
    }

    .text-area {
      margin-top: 50px;
      font-size: 22px;
      font-weight: 400;
      padding-left: 60px;
      padding-bottom: 50px;
      @include mediaScreenContent('sm'){
        padding-left: 110px;
        padding-bottom: 140px;
      }
      @include mediaScreenContent('md'){
        padding-left: 150px;
        padding-bottom: 200px;
      }
      p {
        font-style: normal;
        text-align: left;
        font-family: "Roboto", sans-serif;
        color: #424242;
        font-weight: normal;
        &.highlight-text {
          font-family: "Oswald", serif;
          color: #212121;
          font-size: 24px;
          font-weight: 500;
        }
      }
      object {
        position: absolute;
        top: 40px;
        margin: 0;
        height: 42px;
        left: 10px;
        @include mediaScreenContent('sm'){
          left: 40px;
        }
        @include mediaScreenContent('md'){
          left: 100px;
        }
      }
    }
  }
  .call-to-contact {
    padding: 50px 0;
    h4 {
      width: 223px;
      font-family: "Oswald", serif;
      color: #f5f5f5;
      font-size: 54px;
      font-weight: 700;
      text-align: left;
    }
    .content {
      display: flex;
      flex-direction: column;
      padding: 40px;
      @include mediaScreenContent('md'){
        flex-direction: row;
      }
      p {
        font-family: "Roboto", sans-serif;
        color: #ffffff;
        font-size: 22px;
        font-weight: 400;
        text-align: left;
      }
      & > div {
        float: left;
      }
    }
  }

  .content-5-divs {
    padding-top: 100px;
    .top {
      .left-text {
        font-family: "Roboto", sans-serif;
        color: #424242;
        font-size: 22px;
        font-weight: 900;
        font-style: italic;
        align-self: center;
        background-color: white;
        align-items: flex-start;
        padding: 20px 20px 20px 8%;
      }
    }
    .bottom {
      font-size: 18px;
    }
  }

  .simple-mission {
    font-family: "Roboto", sans-serif;
    text-align: center;
    p {
      margin-top: 20px;
      padding: 20px;
      color: #424242;
      font-size: 22px;
      font-weight: 900;
      font-style: italic;
    }
    .boxed {
      font-family: "Oswald", serif;
      color: #616161;
      font-size: 25px;
      font-weight: 400;
      font-style: normal;
      padding: 10px 30px;
      margin-top: 20px;
      background-color: #ffffff;
      border: 1px solid #616161;
    }
    h3 {
      font-family: "Oswald", serif;
      font-size: 50px;
      font-weight: bold;
    }
  }

  .our-board {
    h3 {
      font-family: "Oswald", serif;
      padding: 8px 20px;
      color: #212121;
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      background: linear-gradient(227deg, rgba(244, 244, 244, 0.95) 0%, rgba(244, 244, 244, 0.95) 34%, rgba(168, 169, 170, 0.95) 70%, rgba(97, 97, 97, 0.95) 100%);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.2);
    }
    & > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      perspective: 1600px;
    }
    .board-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 346px;
      padding-bottom: 40px;
      @include mediaScreenContent('sm'){
        width: 50%;
      }
      @include mediaScreenContent('md'){
        width: 33%;
      }
      @include mediaScreenContent('xl'){
        width: 25%;
      }
      @media (min-width: 1460px){
        width: 16.6%;
      }
      .board-description {
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #C4C4C4;
        width: 234px;
        height: 306px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 10px;
        align-items: center;
        font-family: Oswald;
        font-weight: 500;
        color: $gray800;
        padding: 5px;
        .name {
          font-size: 23px;
          line-height: 39px;
          text-transform: uppercase;
        }
        small {
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 5px;
          color: $gray700;
          text-align: center;
          text-transform: uppercase;
        }
        p {
          font-family: Roboto;
          font-size: 14px;
          line-height: 19px;
          padding-left: 25px;
          &:before {
            content: url("/assets/images/bp.svg");
            position: absolute;
            left: 5px;
          }
        }
      }

      .board-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 234px;
        height: 306px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 10px;
        background: url("/assets/images/aboutus/whitebg2.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        span {
          font-family: Oswald;
          font-weight: bold;
          font-size: 55px;
          line-height: 55px;
          text-align: center;
          text-transform: uppercase;
        }
        img {
          border-radius: 50%;
          width: 120;
          height: 120;
          margin: 35px 0 10px 0;
        }
      }
    }
  }

  .memberships {
    padding: 50px;
    font-family: "Oswald", serif;
    color: #424242;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    @include mediaScreenContent('md'){
      text-align: left;
    }
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      max-height: 73px;
      margin-top: 10px;
      @include mediaScreenContent('md'){
        margin-top: 0;
      }
    }
  }

  .contact-form {
    width: 100%;
  }
  @include pagesUlWithIcons;
  @include pagesB2bContainer;
  .b2b-container {
    object {
      height: 57px;
    }
  }

  .card6 {
    height: auto;
    padding-top: 20px;
    .info {
      background: none;
      .info-area {
        height: auto;
      }
    }
  }
}