// we are using parent to have more weigth in classes
.content-section .iconsportswire-page {
  background-color: #212121;
  height: auto;

  .text {
    width: 80%;
  }

  .card3 {
    height: auto;
    min-height: 540px;
    @include mediaScreenContent('sm'){ min-height: 580px; }
    background-repeat: no-repeat !important;
    background-position: top !important;
    background-size: cover !important;
  }
  .bg-card3 {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 280px 280px !important;
    @include mediaScreenContent('sm'){
      background-size: auto !important;
    }
    display: flex;
    justify-content: center;
    .content {
      padding-top: 190px;
    }
  }

  .mid-content {
    & > div {
      width: 100%;
      padding: 40px;
      @include mediaScreenContent('md'){
        width: 50%;
      }
    }

    & > div:nth-child(1) {
      padding-left: 10%;
    }
    & > div:nth-child(2) {
      padding-right: 10%;
    }
    .publications {
      p {
        font-family: "Oswald", serif;
        color: #bdbdbd;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        border: 1px solid #616161;
        padding: 10px;
      }
      .publications-logos {
        text-align: center;
        img {
          margin: 10px;
          max-height: 25px;
          @include mediaScreenContent('sm'){
            max-height: 32px;
          }
          @include mediaScreenContent('md'){
            max-height: 40px;
          }
        }
      }
    }

    .other-text {
      font-family: "Roboto", sans-serif;
      color: #9e9e9e;
      font-size: 24px;
      @include mediaScreenContent('sm'){
        font-size: 26px;
      }
      font-weight: 400;
      text-align: center;
      span {
        font-weight: bold;
        font-style: italic;
      }
    }
  }

  .surf-bg {
    display: none;
    @include mediaScreenContent('md'){
      display: block;
    }
    min-height: 280px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }

  .among-text {
    .highlight-silver {
      font-size: 40px;
      @include mediaScreenContent('md'){
        font-size: 30px;
      }
    }
    img {
      object-fit: contain;
      max-height: 300px;
    }
  }

  @include pagesB2bFixed;


  @include pagesUlWithIcons;
  @include pagesB2bContainer;
}