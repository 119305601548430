// we are using parent to have more weigth in classes
.content-section .sportsforecaster-page {
  background-color: black;
  height: auto;
  .bg-sf{
    margin-right: -15px;
    margin-left: -15px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }
  .card4 {
    height: auto;
    padding-top: 185px;
    display: flex;
    flex-direction: column;
    @include mediaScreenContent('md'){
      padding-top: 180px;
    }
    .text {
      color: white;
      font-size: 32px;
    }
  }



  .description {
    margin: 15px 40px;
    max-width: 740px;
    display: flex;
    flex-direction: column;
    @include mediaScreenContent('md'){
      flex-direction: row;
      margin: 0 auto;
    }
    .description-text {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      font-family: "Oswald", serif;
      color: #e0e0e0;
      font-size: 40px;
      font-weight: 500;
      text-align: left;
      background: linear-gradient(231deg, rgba(238, 116, 51, 0.8) 0%, rgba(156, 64, 29, 0.8) 34%, rgba(117, 48, 21, 0.8) 52%, rgba(0, 0, 0, 0.8) 100%);
      opacity: 0.9;
      flex: 1;
      @include mediaScreenContent('md'){
        flex: 0 0 56%;
      }
    }
    ul {
      padding: 20px;
      height: 100%;
      list-style: none;
      background-color: #212121;
      opacity: 0.9;
      flex: 1;
      @include mediaScreenContent('md'){
        flex: 0 0 44%;
      }
      li {
        font-family: "Roboto", sans-serif;
        color: #bdbdbd;
        font-size: 24px;
        font-weight: 400;
        font-style: normal;
        text-align: left;
        img {
          height: 30px;
          margin-right: 5px;
        }
      }
    }
  }

  .middle-part {
    font-family: "Roboto", sans-serif;
    color: #9e9e9e;
    font-size: 26px;
    letter-spacing: 0px;
    line-height: 1.2;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    p {
      margin: 120px auto 40px auto;
      max-width: 461px;
    }
    span {
      font-weight: bold;
      font-style: italic;
    }
    img {
      height: 290px;
      opacity: 0.6;
      &.dark {
        opacity: 0.4;
      }
      &.focused {
        height: 330px;
        opacity: 1;
      }
    }
  }
  @include pagesB2bFixed;


  @include pagesUlWithIcons;
  @include pagesB2bContainer;
}