// we are using parent to have more weigth in classes
.content-section .apisportswire {
  background-color: black;
  height: auto;
  .top-syndicated {
    margin-top: auto;
    margin-bottom: 30px;
    @include mediaScreenContent('md'){
      margin: auto;
    }
  }
  .card2 {
    height: auto;
    min-height: 550px;
    background-repeat: no-repeat !important;
    background-position: top !important;
    background-size: cover !important;
    align-items: unset;
    flex-direction: column;

    .content {
      padding-top: 201px;
    }
    .text {
      color: white;
      font-size: 32px;
      margin-top: 15px;
    }
  }
  @include pagesB2bFixed;

  .silver-bg {
    font-family: "Oswald", serif;
    color: #212121;
    font-size: 25px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: center;
    background: linear-gradient(227.71299260264053deg, rgba(244, 244, 244, 0.95) 0%, rgba(244, 244, 244, 0.95) 34.040823578834534%, rgba(168, 169, 170, 0.95) 70.3125%, rgba(97, 97, 97, 0.95) 100%);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  }
  p {
    font-family: "Roboto", sans-serif;
    color: #9e9e9e;
    font-size: 22px;
    letter-spacing: 0px;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    span {
      font-weight: bold;
      font-style: italic;
    }
  }
  .back-api {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    height: 200px;
    width: 100%;
    margin-top: -80px;
  }

  @include pagesUlWithIcons;
  @include pagesB2bContainer;
}