// fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Lato:wght@400%3b700&family=Oswald:wght@300%3b500&family=Roboto:wght@400%3b700&display=swap");

@mixin fontOswald {
  font-family: 'Oswald', sans-serif;
}
@mixin fontRoboto {
  font-family: 'Roboto', sans-serif;
}
@mixin fontLato {
  font-family: 'Lato', sans-serif;
}

// variables
$media-screens: ("sm": 576px, "md": 768px, "lg": 992px, "xl": 1200px, "xxl": 1400px);
$black: #000000;
$white: #ffffff;
$gray100: #F5F5F5;
$gray200: #EEEEEE;
$gray300: #E0E0E0;
$gray400: #BDBDBD;
$gray500: #9e9e9e;
$gray700: #616161;
$gray800: #424242;
$gray900: #212121;

// variables zindex
$ZIndexMenuSidebar: 101;
$ZIndexMenuShadow: 100;

// functions
@function mediaScreen($sizeName) {
  @return map-get($media-screens, $sizeName);
}

@mixin mediaScreenContent($sizeName) {
  @media (min-width: mediaScreen($sizeName)) {
    @content;
  }
}