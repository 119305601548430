.content-section {
  flex: 1;
  position: relative;
  height: 100%;
  background-color: white;
  overflow: auto;
  padding-right: 0px; // this is changed with JS
  /*
    /////
    this one is the clas for the childs
    /////
  */
  & > div {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }
}

.card1 {
  position: relative;
  height: 100%;
  .text {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 100px;
    padding-left: 8%;
    @include mediaScreenContent('md'){
      align-items: center;
      padding-top: 0;
    }
    p {
      font-size: 35px;
      @include mediaScreenContent('sm'){
        font-size: 50px;
      }
      @include mediaScreenContent('md'){
        font-size: 60px;
      }
      @include mediaScreenContent('lg'){
        font-size: 70px;
      }
      font-family: roboto;
      font-weight: 900;
      font-style: normal;
      letter-spacing: 0.0001px;
      padding-left: 0px;
      line-height: 1;
      background: rgba(0, 0, 0, 0) linear-gradient(345deg, rgb(181, 181, 181) 0%, rgb(196, 196, 196) 50%, rgb(242, 242, 242) 100%) repeat scroll 0% 0% padding-box;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .iphone {
    position: absolute;
    top: 150px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: calc(100% - 150px);
    @include mediaScreenContent('sm'){
      right: 50px;
    }
  }
  .scroll-down {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Oswald", serif;
    color: #f5f5f5;
    font-size: 14px;
    letter-spacing: 1.12px;
    line-height: 1.2;
    font-weight: 400;
    bottom: 25px;
    right: 25px;
    @include mediaScreenContent('sm'){
      bottom: 100px;
      right: 45px;
    }
    @include mediaScreenContent('md'){
      bottom: 150px;
      right: 50px;
    }
    & img {
      padding-bottom: 20px;
      max-width: 50px;
    }
  }
}

.card2 {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .api-sportswire {
      img {
        height: 40px;
        &:nth-child(1){
          margin-right: 15px;
        }
        @include mediaScreenContent('sm'){
          height: 50px;
        }
        @include mediaScreenContent('md'){
          height: 70px;
        }
      }

    }
    .text {
      width: 80%;
      font-size: 26px;
      @include homeCardText;
      @include mediaScreenContent('sm'){
        width: 100%;
        font-size: 28px;
      }
    }
    .button-view {
      @include buttonTransparent;
    }
  }
  .b2b {
    @include homeB2bComponent;
  }
}

// iconsportswire
.card3 {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 280px 280px !important;
  @include mediaScreenContent('sm'){
    background-size: auto !important;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .iconsportswire {
      img {
        height: 40px;
        @include mediaScreenContent('sm'){
          height: 50px;
        }
        @include mediaScreenContent('md'){
          height: 70px;
        }
      }
    }
    .text {
      @include homeCardText;
      color: white;
      font-size: 32px;
    }
    .button-view {
      @include buttonTransparent;
      position: absolute;
      bottom: -150px;
      @include mediaScreenContent('sm'){
        bottom: -100px;
      }
    }
  }
  .b2b {
    @include homeB2bComponent;
  }
}

// sportsforecaster
.card4 {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0) linear-gradient(-90deg, rgba(166, 67, 15, 0.55) 0%, rgba(0, 0, 0, 0.55) 51.634%) repeat scroll 0% 0%;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .sportsforecaster {
      opacity: 0.7;
      font-family: "Oswald", serif;
      color: #ffffff;
      letter-spacing: 0px;
      line-height: 1.2;
      font-weight: 700;
      font-style: normal;
      text-align: center;
      font-size: 35px;
      @include mediaScreenContent('sm'){
        font-size: 55px;
      }
      @include mediaScreenContent('md'){
        font-size: 70px;
      }
    }
    .text {
      @include homeCardText;
      font-size: 28px;
      width: 80%;
      @include mediaScreenContent('sm'){
        width: 100%;
      }
    }
    .button-view {
      @include buttonTransparent;
    }
  }
  .b2b {
    @include homeB2bComponent;
  }
}

// forecastergames
.card5 {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .iconsportswire {
      img {
        height: 40px;
        @include mediaScreenContent('sm'){
          height: 50px;
        }
        @include mediaScreenContent('md'){
          height: 70px;
        }
      }
    }
    .text {
      margin-top: 15px;
      @include homeCardText;
      font-size: 32px;
      color: #212121;
    }
    .button-view {
      @include buttonTransparent;
      border-color: black;
      color: black;
    }
  }
  .b2b {
    @include homeB2bComponent;
  }
}

// contact
.card6 {
  position: relative;
  height: 100%;
  .info {
    width: 100%;
    @include mediaScreenContent('md'){
      width: 45%;
    }
    display: flex;
    flex-direction: column;
    background-color: rgb(66, 66, 66);

    .contact-image {
      object-fit: cover;
    }

    .contact-info {
      // this should be parent of info-area.
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .info-area {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Roboto", sans-serif;
      .left {
        padding: 20px;
        width: 50%;
        height: 100%;
        color: #424242;
        font-size: 14px;
        @include mediaScreenContent('sm'){
          font-size: 16px;
        }
        @include mediaScreenContent('md'){
          font-size: 19px;
        }
        letter-spacing: 0px;
        line-height: 1.2;
        font-weight: 500;
        text-align: left;
        .phone {
          font-size: 17px;
          @include mediaScreenContent('sm'){
            font-size: 19px;
          }
          @include mediaScreenContent('md'){
            font-size: 22px;
          }
          line-height: 1;
          font-weight: 900;
        }
        object {
          height: auto;
          width: 100%;
        }
        & > div {
          padding: 10%;
          background-color: rgb(237, 237, 237);
          border-radius: 12.8619px;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 10.7182px 21.4364px 0px;
          height: 100%;
        }
      }
      .right {
        width: 50%;
        padding-top: 20px;
        color: #bdbdbd;
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 1.2;
        font-weight: 400;
        text-align: left;
        small {
          font-weight: 900;
        }
        ul {
          list-style: none;
          padding: 10px;
          li {
            display: flex;
            margin-bottom: 10px;
            font-size: 17px;
            @include mediaScreenContent('sm'){
              font-size: 19px;
            }
            @include mediaScreenContent('md'){
              font-size: 22px;
            }
            a {
              text-decoration: none;
              color: inherit;
            }
          }
          img, object {
            width: 26px;
            margin-right: 10px;
            @include mediaScreenContent('sm'){
              width: 30px;
              margin-right: 15px;
            }
          }
        }
      }

    }
  }
}