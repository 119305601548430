// we are using parent to have more weigth in classes
.content-section .forecastergames-page {
  background-color: #F5F5F5;
  height: auto;
  .card5 {
    padding-top: 100px;
    @include mediaScreenContent('md'){
      padding-top: 100px;
    }
    flex-direction: column;
    background-repeat: no-repeat !important;
    background-position: top !important;
    background-size: cover !important;
    .text {
      width: 80%;
    }
    .separator {
      width: 100%;
      height: 300px;
      background-color: white;
      position: absolute;
      bottom: -50px;
      opacity: .5;
      -webkit-box-shadow: 0px 0px 37px 2px rgba(255,255,255,1);
      -moz-box-shadow: 0px 0px 37px 2px rgba(255,255,255,1);
      box-shadow: 0px 0px 37px 12px rgba(255,255,255,1);
    }
    .bottom-shadow {
      width: 100%;
      height: 1px;
      margin-top: 80px;
      box-shadow: 0px -24px 37px 50px whitesmoke;
      margin-bottom: -20px;
    }
  }
  @include pagesB2bFixed;

  .content {
    margin-top: 80px;
    margin-bottom: 25px;
  }

  .middle-image img {
    width: calc(100% + 20px);
    margin-left: -8px;
    margin-bottom: -15px;
  }

  .cover {
    width: 100%;
    font-family: "Oswald", serif;
    color: #424242;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    height: 300px;
    @include mediaScreenContent('sm'){ height: 330px; }
    @include mediaScreenContent('md'){ height: 360px; }
    @include mediaScreenContent('lg'){ height: 390px; }
    @include mediaScreenContent('xxl'){ height: 440px; }
    p {
      margin-left: 50px;
    }
    .cover-items {
      height: calc(100% + 17px);
      max-height: 410px;
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
      align-items: center;
      div {
        margin-left: 2%;
        flex: 0 0 28%;
        img {
          width: 100%;
          min-width: 350px;
          -webkit-filter: drop-shadow(-26px 10px 15px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(-26px 10px 15px rgba(0, 0, 0, 0.4));
          max-width: 450px;
        }
        &:nth-child(1) {
          display: flex;
          justify-content: flex-end;
        }
        &:nth-child(2) {
          display: flex;
          justify-content: center;
        }

        &.focused {
          flex: 0 0 38%;
          img {
            min-width: 400px;
            max-width: 550px;
          }
        }
      }
      @include mediaScreenContent('lg'){
        justify-content: center;
        div &:nth-child(1){
          margin-left: 0;
        }
      }
    }
  }

  .top-bottom {
    p {
      font-family: "Oswald", serif;
      color: #212121;
      font-size: 40px;
      @include mediaScreenContent('md'){
        font-size: 45px;
      }
      font-weight: 700;
      text-align: center;
      &.boxed {
        color: #616161;
        font-size: 25px;
        font-weight: 400;
        padding: 5px 15px;
        margin:15px;
        @include mediaScreenContent('md'){
          padding: 5px 30px;
        }
        background-color: #ffffff;
        border: 1px solid #616161;
      }
    }
  }

  .teams {
    white-space: nowrap;
    img {
      margin-left: 45px;
      max-width: 82px;
      object-fit: contain;
    }
  }

  .teams-text {
    font-family: "Roboto", sans-serif;
    color: #424242;
    font-size: 22px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }


  @include pagesUlWithIcons;
  @include pagesB2bContainer;
}