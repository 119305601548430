.main {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.full-site-button {
  position: fixed;

  right: 25px;
  top: 110px;
  @include mediaScreenContent('sm'){
    right: 50px;
    top: 135px;
  }
  @include mediaScreenContent('md'){
    right: 100px;
    top: 185px;
  }
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #BDBDBD;
  a {
    text-decoration: none;
    font-family: "Oswald", serif;
    color: #BDBDBD;
    font-size: 14px;
    letter-spacing: 1.12px;
    line-height: 1.2;
    font-weight: 600;
    text-align: center;
  }
}

.footer-text {
  margin-top: 100px;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  color: #9e9e9e;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
  padding-bottom: 100px;
  @include mediaScreenContent('md'){
    padding-bottom: 20px;
  }
}

.green-button {
  @include greenButon;
  &.fixed-button {
    position: fixed;
    right: 25px;
    top: 75px;
    @include mediaScreenContent('sm'){
      right: 50px;
    }
  }
}

.contact-form {
  width: 100%;
  @include mediaScreenContent('md'){
    width: 55%;
  }
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  font-family: "Oswald", serif;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
  form {
    width: 100%;
  }
  h3 {
    color: #f5f5f5;
    font-size: 54px;
    font-weight: 700;
  }
  .framed {
    border: 1px solid #ffffff;
    color: #ffffff;
    padding: 5px;
    font-size: 28px;
    font-weight: 400;
  }
  input {
    width: 100%;
    height: 40px;
    padding: 5px;
    background-color: #fbfcfd;
    box-shadow: 0px 3px 2px 0px rgba(152, 162, 179, 0.1), 0px 1px 1px 0px rgba(152,162,179,0.10000000149011612);
    border: 1px solid #e0e0e0;
  }
  textarea {
    width: 100%;
    padding: 5px;
  }
  .contactButton {
    @include greenButon;
  }
}

.bg-nr-center-cover{
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.content-5-divs {
  .top {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    & > div {
      width: 100%;
    }
    @include mediaScreenContent('md'){
      flex-direction: row;
      .middle-image {
        width: 38%;
        flex: 0 0 38%;
        filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.5));
      }
      .right-image {
        flex: 0 0 20%;
      }
    }
    .left-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
      justify-content: center;
      font-family: "Oswald", serif;
      color: #212121;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 500;
      text-align: left;
      background: transparent;

      object {
        width: 210px;
      }
      h3 {
        margin-top: 50px;
        font-size: 30px;
      }
      @include mediaScreenContent('md'){
        flex: 0 0 42%;
        background-color: #ededed;
        align-items: center;
        padding: 60px 0;
      }
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .bottom {
    & > div {
      float: left;
    }
    width: 100%;
    font-family: "Roboto", sans-serif;
    color: #424242;
    font-weight: 400;
    font-size: 22px;
    text-align: left;
    .left-text {
      background-color: #e0e0e0;
      width: 100%;
      padding: 20px;
      @include mediaScreenContent('md'){
        width: 42%;
        height: 100%;
        padding: 20px 20px 20px 8%;
      }
    }
    .right-text {
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e0e0e0;
      span {
        font-weight: bold;
        font-style: italic;
      }
      @include mediaScreenContent('md'){
        padding: 20px 8% 20px 20px;
        background-color: #ededed;
        width: 58%;
        height: 100%;
      }
    }
  }
}

.highlight {
  background: linear-gradient(244deg, rgba(252, 142, 41, 0.9) 43.65%, rgba(238, 116, 51, 0.9) 65.25%, rgba(0, 0, 0, 0.9) 155.73%) !important;
  @include textGradient;
}

.highlight-white {
  color: white;
  font-family: Oswald;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;

  @include mediaScreenContent('sm'){
    font-size: 50px;
  }
  @include mediaScreenContent('md'){
    font-size: 70px;
  }
}

.highlight-silver {
  font-family: Oswald;
  font-weight: bold;
  font-size: 40px;
  @include mediaScreenContent('sm'){
    font-size: 50px;
  }
  @include mediaScreenContent('md'){
    font-size: 70px;
  }
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(27deg, rgba(97,97,97,1) 0%, rgba(168,169,170,1) 27%, rgba(244,244,244,1) 100%);
  @include textGradient;
}

.highlight-dark {
  background: linear-gradient(244deg, #EE7433 0%, #9D411D 34.04%, #753016 52.47%, #000000 100%) !important;
  @include textGradient;
}

.highlight-darker {
  background: linear-gradient(228deg, rgba(238, 116, 51, 0.9) 23%, rgba(157, 65, 29, 0.9) 44%, rgba(117, 48, 22, 0.9) 56%, rgba(0, 0, 0, 0.9) 85%) !important;
  @include textGradient;
}

.p-25 {
  padding: 25px;
}